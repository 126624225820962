<template>
  <div class="track-advanced">
    <row background-color="#1d4962">
      <back-btn class="back-btn-wid" label="Tilbage" @click="backClick" />
      <statusbar :items="statusItems" :current="activate_icons" />
      <status-message
        v-if="statusMessage.isVisible"
        :header="statusMessage.header"
        :message="statusMessage.message"
      />
    </row>
    <row>
      <columns>
        <column>
          <greeting-message
            v-if="greetingMessage.isVisible"
            :header="greetingMessage.header"
            :message="greetingMessage.message"
          />
          <delivery-message
            v-if="deliveryMessage.isVisible"
            :header="deliveryMessage.header"
            :time="deliveryMessage.time"
            :date="deliveryMessage.date"
          />
        </column>
        <column>
          <recipient-card
            :recipient="recipient"
            @options-click="optionsClick"
            @signature-click="signatureClick"
          />
        </column>
      </columns>
    </row>
    <row>
      <btn label="Leveringsmuligheder" width="200px" right @click="deliveryClick" />
    </row>
    <row>
      <package-list :packages="packages" pod-enabled @pod-click="podClick" />
    </row>
    <row>
      <btn
        class="bottom-btn"
        label="Spor en anden forsendelse"
        center
        @click="trackDelivery"
      /><br /><br /><br />
    </row>
    <div>
      <Deliverymodal
          v-show="isModalVisible"
          @close="closeModal"
          @selectedOption="selectedOption"
      />

      <Additionalmodal
          v-show="isAddModalVisible"
          :deliveryText="deliveryText"
          @closeadd="closeModalAdd"
          @acceptClick="acceptClick"
      />
    </div>
  </div>
</template>

<script>
  import Row from "@/components/Row";
  import Columns from "@/components/Columns";
  import Column from "@/components/Column";
  import BackBtn from "@/components/BackBtn";
  import Statusbar from "@/components/Statusbar";
  import StatusMessage from "@/components/StatusMessage";
  import GreetingMessage from "@/components/GreetingMessage";
  import DeliveryMessage from "@/components/DeliveryMessage";
  import RecipientCard from "@/components/RecipientCard";
  import PackageList from "@/components/PackageList";
  import Btn from "@/components/Btn";
  import axios from "axios";
  import Deliverymodal from '@/components/DeliveryModel.vue';
  import Additionalmodal from '@/components/AdditionalModal.vue';

  export default {
    name: "Shipment",
    components: {
      Row,
      Column,
      Columns,
      BackBtn,
      Statusbar,
      StatusMessage,
      GreetingMessage,
      DeliveryMessage,
      RecipientCard,
      PackageList,
      Btn,
      Deliverymodal,
      Additionalmodal
    },
    data() {
      // Dummy data
      return {
        deliveryText:'',
        isAddModalVisible:false,
        isModalVisible: false,

        statusItems: [
          { id: "data", label: "Data modtaget" },
          { id: "received", label: "Forsendelse modtaget" },
          { id: "planned", label: "Rute planlagt" },
          { id: "delivering", label: "På vej til dig" },
          { id: "delivered", label: "Leveret" },
        ],
        activate_icons:0,
        deliveryMessage_new:"",
        recipient_new:{
          weight:"",
          volume:'',
        },
        statusMessage: {
          isVisible: true,
          header: "En eller flere pakker er under levering",
          message:
            "For mere information, klik på bjælken ‘Overblik over pakker’ nedenfor.",
        },
        greetingMessage: {
          isVisible: true,
          header: "",
          message: "",
        },
        deliveryMessage: {
          isVisible: true,
          header: "",
          time: "",
          date: "",
        },
        recipient: {
          orderNumber: "",
          name: "",
          address: "",
          zip: "",
          city: "",
          country: "",
          phone: "",
          date: "",
          time: "",
          weight:"",
          volume:"",
          services: "",
          comment: "",
        },
        packages: [
          // {
          //   packageNumber: "",
          //   //description: "Pakkens indhold beskrives her",
          //   weight: "",
          //   volume: "",
          //   status: "Din pakke forventes at blive leveret i løbet af dagen",
          //   statuses: [
          //     // {
          //     //   description: "Din pakke er ankommet til et BFT depot",
          //     //   date: "26.03.2021 14:10",
          //     //   location: "Danmark Køge",
          //     // },
          //     // {
          //     //   description: "Din pakke er ankommet til BFT depot",
          //     //   date: "26.03.2021 14:10",
          //     //   location: "Danmark Køge",
          //     // },
          //     // {
          //     //   description: "Din pakke er ankommet til BFT",
          //     //   date: "26.03.2021 14:10",
          //     //   location: "Danmark Køge",
          //     // },
          //     // {
          //     //   description:
          //     //     "Afsender har opretttet label til di pakke, men den er ikke kommet til os endnu. Evt. er chaufføren ikke ankommet til depotet eller afsender har ikke givet os pakken endnu. Prøv igen efter kl. 20.00. Er der stadig ingen scanninger, så kontakt afsender.",
          //     //   date: "26.03.2021 14:10",
          //     //   location: "Danmark Køge",
          //     // },
          //   ],
          //   photos: [
          //     "images/photos/photo.jpg",
          //     "images/photos/photo.jpg",
          //     "images/photos/photo.jpg",
          //   ],
          //   location: { lat: 0, lng: 0 },
          // }
        ],
        mytoken: "",
        headerdata:[],
        shipmentdata:[],
        exactDeliveryTime : ""
      };
    },
    created() {
      this.mytoken = localStorage.getItem("token");
      if(this.mytoken == null || this.mytoken == ""){
        this.loggedIn = false;
        this.$router.push({ name: 'Login'}); 
      }else{

        this.mytoken = localStorage.getItem("token");
      
        var config = {
          method: "get",
          url: "https://engine.bftlogistik.dk:7000/partner/v1/checktoken/"+this.mytoken
        };
        axios(config)
        .then((response) => {
          if(response.data.token.status == true){
            this.loggedIn = true;
            this.getshipmentdata();
          }
        })
        .catch(function (error) {
          console.log(error);
          if(error.response.status == 404)
          {
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            localStorage.removeItem("startDate");
            localStorage.removeItem("endDate");
            localStorage.removeItem("pageSize");
            localStorage.removeItem("currentPage");
            localStorage.setItem("loggedIn", false);
            location.reload();
          }
        });
      }
    },
    methods: {

      getshipmentdata(){
        this.mytoken = localStorage.getItem("token");
        var number = this.$route.query.number;

        var config_new = {
          method: "get",
          url: "https://engine.bftlogistik.dk:7000/partner/v2/getheader/" + this.mytoken +
          "?shipment="+number
        }
        axios(config_new)
        .then((response) => {
          //console.log(response.data.header.icons);   
          this.activate_icons = parseInt(response.data.header.icons);
          var deliverydate = response.data.header.Date;
          this.deliveryMessage.date = new Date(deliverydate).getDate()+'. '+(new Date(deliverydate).toLocaleString('default', { month: 'long' }))+' '+new Date(deliverydate).getFullYear();
          //this.deliveryMessage.time = response.data.header.DelTime;
          this.recipient.services = response.data.header.serviceText;
          this.recipient.weight = Math.round(response.data.header.Weight);
          this.recipient.volume = Number(response.data.header.Volumen).toFixed(3);

          this.greetingMessage.message = response.data.header.deliveryText;

          if(response.data.header.icons == '5'){
              this.deliveryMessage.header = "Forsendelse er leveret : ";

              var deliveryTime = response.data.header.exactDeliveryTime;
              this.exactDeliveryTime = new Date(deliveryTime).getHours().toString().padStart(2, '0')+':'+new Date(deliveryTime).getMinutes().toString().padStart(2, '0');

              this.deliveryMessage.time = this.exactDeliveryTime;
              this.greetingMessage.header = response.data.header.company;
          }else{
            this.deliveryMessage.header = "Forventet levering mellem : ";
            this.deliveryMessage.time = response.data.header.DelTime;
            this.greetingMessage.header = response.data.header.company;
          }
        })
        .catch(function (error) {
          console.log(error);
        });

        var config = {
          method: "get",
          url: "https://engine.bftlogistik.dk:7000/partner/v3/getsingleshipment/" + this.mytoken +
          "?shipment=" + number
        };
        axios(config)
        .then((response) => {
          //console.log(response.data.shipment);
          this.shipmentdata = response.data.shipment;

          // var deliverydate = this.shipmentdata.Date;
          // this.deliveryMessage.date = new Date(deliverydate).getDate()+'. '+(new Date(deliverydate).toLocaleString('default', { month: 'long' }))+' '+new Date(deliverydate).getFullYear();
          this.recipient.orderNumber = this.shipmentdata.CustomerOrderRef;
          this.recipient.name = this.shipmentdata.DestName;
          this.recipient.address = this.shipmentdata.DestAdr;
          this.recipient.zip = this.shipmentdata.DestZip;
          this.recipient.city = this.shipmentdata.DestCity;
          this.recipient.phone = this.shipmentdata.DestTelephone_Mob;
          this.recipient.date = new Date(this.shipmentdata.crtDate).getDate().toString().padStart(2, '0')+'/'+(new Date(this.shipmentdata.crtDate).getMonth()+1).toString().padStart(2, '0')+'/'+new Date(this.shipmentdata.crtDate).getFullYear();
          this.recipient.time = new Date(this.shipmentdata.crtDate).getHours().toString().padStart(2, '0')+':'+new Date(this.shipmentdata.crtDate).getMinutes().toString().padStart(2, '0');
          this.recipient.comment = this.shipmentdata.DeliveryText;

          response.data.packages.forEach((element) => {
            let temp = [];
            let photos = [];
            let location = [];
            var config = {
              method: "get",
              url: "https://engine.bftlogistik.dk:7000/partner/v3/packagescans/" + element.pakke_ID,
              //url: "https://engine.bftlogistik.dk:7000/partner/v3/packagescans/00370730255379379433",
              headers: {
                "Content-Type": "application/json"
              }
            };
            axios(config)
            .then((response) => {
              //console.log(response.data.tracking);
              if(response.data.scans.length > 0){
                response.data.scans &&
                response.data.scans.forEach((elem) => {
                  temp.push({
                    description :elem.line,
                    location :elem.Status,
                    date :new Date(elem.DatoTid).getDate().toString().padStart(2, '0')+'.'+(new Date(elem.DatoTid).getMonth()+1).toString().padStart(2, '0')+'.'+new Date(elem.DatoTid).getFullYear()+' '+new Date(elem.DatoTid).getHours().toString().padStart(2, '0')+':'+new Date(elem.DatoTid).getMinutes().toString().padStart(2, '0')
                  });
                });
              }

              var config = {
                method: "get",
                url: "https://engine.bftlogistik.dk:7000/partner/v2/tracktraceadvanced/" + this.mytoken +
                "/" + element.pakke_ID,
                headers: {
                  "Content-Type": "application/json"
                }
              };
              axios(config)
              .then((response) => {
                response.data.tracking.forEach((elem) => {
                  if(elem.url != ""){
                    photos.push(elem.url);
                  }
                });
                var index = response.data.tracking.length -1;
                if(response.data.tracking[index].latitude != "0" && response.data.tracking[index].longitude != "0"){
                  location.push({
                    lat: parseFloat(response.data.tracking[index].latitude),
                    lng: parseFloat(response.data.tracking[index].longitude)
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });

              this.packages.push({
                packageNumber : element.pakke_ID,
                weight : Math.round(element.Weight_pakke),
                volume:  Number(element.Volumen_pakke).toFixed(3),
                //status: this.greetingMessage.message,
                status : "klik her for pakkedetaljer",
                content : element.content,
                statuses : temp,
                photos : photos,
                location : location
              })
            })
            .catch(function (error) {
              console.log(error);
            });
          });

          //====== old start =======//
          
          // response.data.packages.forEach((element) => {
          //   let temp = [];
          //   let photos = [];
          //   let location = [];
          //   var config = {
          //     method: "get",
          //     url: "https://engine.bftlogistik.dk:7000/partner/v2/tracktraceadvanced/" + this.mytoken +
          //     "/" + element.pakke_ID,
          //     headers: {
          //       "Content-Type": "application/json"
          //     }
          //   };
          //   axios(config)
          //   .then((response) => {
          //     //console.log(response.data.tracking);
          //     if(response.data.tracking){
          //       response.data.tracking &&
          //       response.data.tracking.forEach((elem) => {
          //         temp.push({
          //           description :elem.line,
          //           location :elem.text,
          //           date :new Date(elem.datotid).getDate().toString().padStart(2, '0')+'.'+(new Date(elem.datotid).getMonth()+1).toString().padStart(2, '0')+'.'+new Date(elem.datotid).getFullYear()+' '+new Date(elem.datotid).getHours().toString().padStart(2, '0')+':'+new Date(elem.datotid).getMinutes().toString().padStart(2, '0')
          //         });
          //         if(elem.url != ""){
          //           //console.log(elem.url);
          //           photos.push(elem.url);
          //         }
          //         // if(elem.latitude != "0" && elem.longitude != "0"){
          //         //   location.push({
          //         //     lat: parseFloat(elem.latitude),
          //         //     lng: parseFloat(elem.longitude)
          //         //   });
          //         // } 
          //       });
          //       var index = response.data.tracking.length -1;
          //       if(response.data.tracking[index].latitude != "0" && response.data.tracking[index].longitude != "0"){
          //         location.push({
          //           lat: parseFloat(response.data.tracking[index].latitude),
          //           lng: parseFloat(response.data.tracking[index].longitude)
          //         });
          //       }
          //     }
          //     //console.log(photos);
          //     this.packages.push({
          //       packageNumber : element.pakke_ID,
          //       weight : Math.round(this.shipmentdata.Weight_actual),
          //       volume:  Number(this.shipmentdata.Volumen_actual).toFixed(3),
          //       //status: "Din pakke forventes at blive leveret i løbet af dagen",
          //       //status: this.greetingMessage.message,
          //        status : "klik her for pakkedetaljer",
          //       statuses : temp,
          //       photos : photos,
          //       location : location
          //       // location: [
          //       //   {
          //       //     lat:parseFloat("-33.865143"),
          //       //     lng:parseFloat("151.209900")
          //       //   },
          //       //   {
          //       //     lat:parseFloat("40.714"),
          //       //     lng:parseFloat("-74.006")
          //       //   }
          //       // ],
          //     })
          //   })
          //   .catch(function (error) {
          //     console.log(error);
          //   });
          // });

        })
        .catch(function (error) {
          console.log(error);
        });
      },

      optionsClick() {
        console.log("Change delivery options");
      },
      signatureClick() {
        console.log("Signature");
      },
      trackDelivery() {
        console.log("Track another delivery");
      },
      backClick() {
        console.log("Back click");
        this.$router.replace("/overview");
      },
      podClick() {
        console.log("POD click");
      },

      closeModal() {
        this.isModalVisible = false;
      },
      closeModalAdd() {
        this.isAddModalVisible = false;
      },
      deliveryClick(){
        this.isModalVisible = true;
      },
      selectedOption(event){
        this.deliveryText = event;
        console.log(this.deliveryText);
        this.isAddModalVisible = true;
      },
      acceptClick(){
        var ordernumber = this.recipient.orderNumber;
        var deliverydata = {
          "message": this.deliveryText
        }
        var config = {
          method: "post",
          url: "https://engine.bftlogistik.dk:7000/partner/v1/deliverymessage/" + ordernumber,
          headers: {
            "Content-Type": "application/json"
          },
          data: deliverydata
        };
        axios(config)
        .then((response) => {
          console.log(response);
          if(response.data.messageStatus == "ok"){
            alert("Besked sendt til chaufføren");
            this.isModalVisible = false;
            this.isAddModalVisible = false;
          }else{
            alert("mislykkedes");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
    },
  };
</script>
<style scoped>
.back-btn-wid{
  width: 8em !important;
}
.bottom-btn{
  width: 20em !important;
}
</style>
