<template>
  <div class="recipient-card">
    <div class="recipient-card-order">
      <div class="recipient-card-order__header">Ordrenummer</div>
      <div class="recipient-card-order__number">
        {{ recipient.orderNumber }}
      </div>
    </div>
    <div class="recipient-card-recipient__header">Modtagerinformation</div>
    <div class="recipient-card-recipient">
      <div class="recipient-card-recipient-column1">
        <div class="recipient-card-recipient__name-header">Navn</div>
        <div class="recipient-card-recipient__name">{{ recipient.name }}</div>
        <div class="recipient-card-recipient__address-header">Adresse</div>
        <div class="recipient-card-recipient__address">
          {{ recipient.address }}
        </div>
        <div class="recipient-card-recipient__zip-city">
          {{ recipient.zip }} {{ recipient.city }}
        </div>
        <div class="recipient-card-recipient__country">
          {{ recipient.country }}
        </div>
        <div class="recipient-card-recipient__phone-header">Telefonnummer</div>
        <div class="recipient-card-recipient__phone">{{ recipient.phone }}</div>
      </div>
      <div class="recipient-card-recipient-column2">
        <div class="recipient-card-recipient__date-header">
          Oprettelses dato
        </div>
        <div class="recipient-card-recipient__date">{{ recipient.date }}</div>
        <div class="recipient-card-recipient__time">{{ recipient.time }}</div>
        <div class="recipient-card-recipient__date-header">
          weight (kg)
        </div>
        <div class="recipient-card-recipient__date">{{ recipient.weight }}</div>
        <div class="recipient-card-recipient__date-header">
          Volumen
        </div>
        <div class="recipient-card-recipient__date">{{ recipient.volume }}</div>
        <div class="recipient-card-recipient__services-header">
          Booket services
        </div>
        <div class="recipient-card-recipient__service">{{ recipient.services }}</div>
        <!-- <div
          v-for="(service, index) in recipient.services"
          :key="`service-${index}`"
          class="recipient-card-recipient__service"
        >
          {{ service }}
        </div> -->
      </div>
      <div class="recipient-card-recipient-column3">
        <div class="recipient-card-recipient__comment-header">
          Kommentar til chauffør
        </div>
        <div class="recipient-card-recipient__comment">
          {{ recipient.comment }}
        </div>
      </div>
    </div>

    <div class="recipient-card-actions">
      <!-- <div class="recipient-card-actions__support">
        <btn
          label="Skift leveringspræference"
          width="100%"
          @click="$emit('options-click')"
        />
      </div>
      <div class="recipient-card-actions__options">
        <btn
          label="Se underskrift"
          width="100%"
          @click="$emit('signature-click')"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
//import Btn from "@/components/Btn";
export default {
  name: "RecipientCard",
  components: {
    //Btn,
  },
  props: {
    recipient: {
      type: Object,
      default: function () {
        return {};
      },
    },
    recipient_new: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style>
.recipient-card {
  margin-top: 30px;
  background-color: #fff;
  color: #1d4962;
  padding: 20px 16px;
  border-radius: 8px;
}
/* Order */
.recipient-card-order {
  margin-bottom: 20px;
}
.recipient-card-order__header {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: #56bae0;
}
.recipient-card-order__number {
  font-size: 32px;
  font-weight: bold;
  color: #df6138;
}
/* Recipient */
.recipient-card-recipient__header {
  color: #56bae0;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.recipient-card-recipient__name-header,
.recipient-card-recipient__address-header,
.recipient-card-recipient__phone-header,
.recipient-card-recipient__date-header,
.recipient-card-recipient__services-header,
.recipient-card-recipient__comment-header {
  font-weight: bold;
  margin-top: 16px;
}

/* Actions */
.recipient-card-actions {
  margin-top: 30px;
}
.recipient-card-actions__support {
  margin-bottom: 10px;
}

@media screen and (min-width: 1024px) {
  .recipient-card-recipient {
    display: flex;
    flex-wrap: wrap;
  }

  .recipient-card-recipient-column1 {
    width: 50%;
  }
  .recipient-card-recipient-column2 {
    width: 50%;
  }
  .recipient-card-recipient-column3 {
    width: 100%;
  }

  .recipient-card-actions {
    display: flex;
    justify-content: space-between;
  }
}
</style>
