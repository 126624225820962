<template>
  <button class="back-btn" @click="$emit('click')">
    {{ label }}
  </button>
</template>

<script>
export default {
  name: "Btn",
  props: {
    label: { type: String, default: "" },
  },
};
</script>

<style>
.back-btn {
  background-color: rgba(255, 255, 255, 0);
  color: #fff;
  height: 42px;
  padding: 0 20px;
  border-radius: 8px;
  border: 1px solid #fff;
  outline: none;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 20px;
}

@media screen and (min-width: 1024px) {
  .back-btn {
    position: absolute;
    top: 32px;
  }
}

@media (hover: hover) {
  .back-btn {
    transition: background-color 0.1s;
  }
  .back-btn:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
</style>
